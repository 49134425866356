// pdfGenerator.js global function
import useJwt from '@/auth/jwt/useJwt'

import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

export async function generateAndDownloadPdf(vueInstance, htmlContent, periodoFiltro, nomeRelatorio, relatorioPaisagem) {
  if (typeof vueInstance.sweetAlert === 'function') {
    vueInstance.sweetAlert(true)
  } else {
    Swal.fire({
      title: 'Carregando...',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
  }

  const style = `
  <head>
  <meta name="viewport" content="width=device-width,initial-scale=1,shrink-to-fit=no">
  <link type="text/css" rel="stylesheet" href="https://unpkg.com/bootstrap/dist/css/bootstrap.min.css">
  <link type="text/css" rel="stylesheet" href="https://unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.min.css">
  <script src="https://polyfill.io/v3/polyfill.min.js?features=es2015%2CIntersectionObserver"
    crossorigin="anonymous"></script>
  <script src="https://unpkg.com/vue@latest/dist/vue.min.js"></script>
  <script src="https://unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.min.js"></script>
  <script src="https://unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue-icons.min.js"></script>

  <link type="text/css" rel="stylesheet"
    href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" />
  <link rel="stylesheet" type="text/css" href="<%= BASE_URL %>loader.css" />
  <link rel="icon" href="<%= BASE_URL %>favicon.ico" />
  <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;1,400&display=swap"
    rel="stylesheet" />

  <style>
    .fundoRelatorio {
      background-color: #fff;
      word-break: break-word;
    }

    .titulo {
      background-color: inherit;
      justify-content: flex-start;
      word-break: break-all;
      padding-top: 5px;
    }

    .ExportarPDFParcela .thead-light th {
      background-color: #f3f2f700;
      border-color: #4941a6;
      border-block-start: initial;
      color: #4941a6;
      font-size: 10px;
      padding: 10.5px 0;
      inline-size: max-content;
    }

    .lineBlue {
      width: 288px;
      height: 13px;
      background-color: #4941a6;
    }

    .lineGray {
      width: 563px;
      height: 13px;
      background-color: #b8c2cb;
    }

    .instituto {
      font-size: initial;
      padding-left: 20px;
    }

    .tituloRelatorio {
      text-align: center;
      font-weight: 700;
      font-size: large;
      color: #615771;
    }

    .cabecalhoTable {
      margin-bottom: 0;
      font-size: 9px;
      word-wrap: normal;
      white-space: pre-wrap;
    }

    .periodoRelatorio {
      font-weight: 700;
      color: #000;
      text-align: center;
    }

    .totalParcelas {
      font-weight: 700;
      color: #000;
      font-size: 9px;
      padding-bottom: 2px;
      padding-left: 20px;
    }

    .rodapeRelatorio {
      font-size: 11px;
      margin-bottom: 0;
      color: #736c81;
      margin-top: 5px;
      padding-left: 20px;
    }

    .rodapeRelatorioContainer {
      bottom: 0;
    }

    .content-wrapper {
      position: relative;
    }

    #ExportarPDFParcelaId thead tr th {
      width: 100px;
    }

    #ExportarPDFParcelaId tbody tr td {
      padding: 3px;
    }

    #logoAgenda {
      margin: 0 10px;
    }

    div.vue-html2pdf div.card-body {
      padding-top: 0;
      padding-bottom: 0;
    }

    .ExportarPDFParcela {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .margemTotalizador {
      margin-top: 30px;
    }

    .ExportarPDFParcela tbody tr td {
      text-wrap: nowrap;
    }

    .mt-07 {
      margin-top: 0.7rem;
    }

    .mt-05 {
      margin-top: 0.5rem;
    }

    #radiosTipoDePesquisa>label {
      width: 0.5em;
    }

    #ExportarPDFParcelaId tbody>tr>td {
      font-size: 0.55rem;
    }
  </style>
</head>
  `

  const fullHtmlContent = `
    <!DOCTYPE html>
    <html lang="pt-br">
       ${style}
      <body>
       ${htmlContent}
      </body>
    </html>
  `

  try {
    const response = await useJwt.post(
      'relatorio/GetGerarRelatorioPDF',
      {
        htmlContent: fullHtmlContent,
        periodoFiltro,
        nomeRelatorio,
        relatorioPaisagem,
      },
      { responseType: 'blob' },
    )
    handlePdfResponse(response.data, vueInstance)
  } catch (error) {
    console.error('Erro ao enviar HTML para o backend:', error)
    if (typeof vueInstance.sweetAlert === 'function') {
      vueInstance.sweetAlert(false)
    } else {
      Swal.close()
    }
  }
}

function handlePdfResponse(pdfData, vueInstance) {
  try {
    const blob = new Blob([pdfData], { type: 'application/pdf' })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'relatorio.pdf')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    if (typeof vueInstance.sweetAlert === 'function') {
      vueInstance.sweetAlert(false)
    } else {
      Swal.close()
    }
  } catch (error) {
    console.error('Erro ao gerar PDF:', error)
    if (typeof vueInstance.sweetAlert === 'function') {
      vueInstance.sweetAlert(false)
    } else {
      Swal.close()
    }
  }
}
