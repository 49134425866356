<template>
  <b-tabs>
    <b-tab active>
      <template #title>
        <feather-icon icon="PercentIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Amortização</span>
      </template>
      <RelatorioAmortizacao />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="BarChart2Icon" size="18" class="mr-50" />
        <span class="font-weight-bold">Consolidado de Parcelas</span>
      </template>
      <RelatorioConsolidadoParcelas />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="BarChart2Icon" size="18" class="mr-50" />
        <span class="font-weight-bold">Consolidado de Parcelas Recebidas</span>
      </template>
      <RelatorioConsolidadoParcelasRecebidas />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="BarChart2Icon" size="18" class="mr-50" />
        <span class="font-weight-bold">Consolidado de Rentabilidade</span>
      </template>
      <RelatorioConsolidadoRentabilidade />
    </b-tab>
  </b-tabs>
</template>
<script>
  import RelatorioAmortizacao from './RelatorioAmortizacao.vue'
  import RelatorioTaxaOscilacaoRiscos from './RelatorioTaxaOscilacaoRiscos.vue'
  import RelatorioTaxaRentabilidade from './RelatorioTaxaRentabilidade.vue'
  import RelatorioTaxaFundoGarantidor from './RelatorioTaxaFundoGarantidor.vue'
  import RelatorioTaxaAdministracao from './RelatorioTaxaAdministracao.vue'
  import RelatorioConsolidadoParcelas from './RelatorioConsolidadoParcelas.vue'
  import RelatorioConsolidadoParcelasRecebidas from './RelatorioConsolidadoParcelasRecebidas.vue'
  import RelatorioConsolidadoRentabilidade from './RelatorioConsolidadoRentabilidade.vue'
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'

  export default {
    components: {
      RelatorioAmortizacao,
      RelatorioTaxaOscilacaoRiscos,
      RelatorioTaxaRentabilidade,
      RelatorioTaxaFundoGarantidor,
      RelatorioTaxaAdministracao,
      RelatorioConsolidadoParcelas,
      RelatorioConsolidadoParcelasRecebidas,
      RelatorioConsolidadoRentabilidade,
      BCardCode,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        acesso: {
          relatorioTaxasJuros: {
            relatorioAmortizacao: true,
            relatorioTaxaOscilacaoRiscos: true,
            relatorioTaxaRentabilidade: true,
            relatorioTaxaFundoGarantidor: true,
            relatorioTaxaAdministracao: true,
            relatorioConsolidadoParcelas: true,
            relatorioConsolidadoParcelasRecebidas: true,
            relatorioConsolidadoRentabilidade: true,
          },
        },
      }
    },
    computed: {
      todosFalsos() {
        return Object.values(this.acesso).every((valor) => valor === false)
      },
    },
    mounted() {
      // TODO: Criar regras de grupo de acesso. Esse trecho estava inutilizado
      // this.acesso.relatorioTaxasJuros.relatorioAmortizacao = true
      // this.acesso.relatorioTaxasJuros.relatorioTaxaOscilacaoRiscos = true
      // this.acesso.relatorioTaxasJuros.relatorioTaxaRentabilidade = true
      // this.acesso.relatorioTaxasJuros.relatorioTaxaFundoGarantidor = true
      // this.acesso.relatorioTaxasJuros.relatorioTaxaAdministracao = true
      // this.acesso.relatorioTaxasJuros.relatorioConsolidadoParcelas = true
      // this.acesso.relatorioTaxasJuros.relatorioConsolidadoParcelasRecebidas = true
      // this.acesso.relatorioTaxasJuros.relatorioConsolidadoRentabilidade = true
    },
  }
</script>
