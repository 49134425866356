import * as XLSX from 'xlsx'
import { formatarData, formatarValorReais } from '@/libs/utils'

const createSheet = (tableData: any[]) => {
  // cria um objeto de planilha vazio
  const workbook = XLSX.utils.book_new()
  // cria uma planilha vazia e adiciona ao objeto de planilha
  const worksheet = XLSX.utils.aoa_to_sheet([[]])
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório Geral')

  // adiciona dados da tabela à planilha
  XLSX.utils.sheet_add_json(worksheet, tableData, { origin: -1 })

  // salvar o arquivo XLSX
  XLSX.writeFile(workbook, 'relatorio-de-lancamentos.xlsx')
}

export const asDefault = () => {
  return {
    convertToExcelFormat: (item, formatarData, formatarValorReais) => {
      return {
        'CRIADO EM': item.createdAt ? formatarData(item.createdAt) : '',
        'N° DO CONTRATO': item.codigo ?? '',
        'VALOR DA PARCELA': item.valor ? formatarValorReais(item.valor) : '',
        'DATA DE VENCIMENTO': item.vencimento ? formatarData(item.vencimento) : '',
        'STATUS DE LANÇAMENTO': item.statusLancamento == 'Lancado' ? 'Lançado' : item.statusLancamento == 'NaoLancado' ? 'Não Lançado' : '',
        'STATUS DA PARCELA':  item.statusDesconto === 'NaoDescontada' ? 'Parcela Não Descontada' :
                              item.statusDesconto === 'Descontado' ? 'Parcela Descontada' :
                              item.statusDesconto === 'Rejeitada' ? 'Parcela Rejeitada' :
                              item.statusDesconto === 'Renegociada' ? 'Parcela Renegociada' :
                              item.statusDesconto == 'PagaParcialmente' ? 'Parcela Paga Parcialmente' : '',
        'NOME DO TOMADOR': item.nomeCompleto ?? '',
        'MATRICULA': item.matricula ?? '',
        'PARCELA': item.numeroParcelas ?? '',
        'ÓRGÃO': item.orgao ?? '',
      }
    },
    export: (items) => {
      const situationItems = items.map((item) => asDefault().convertToExcelFormat(item, formatarData, formatarValorReais))
      createSheet(situationItems)
    },
  }
}
