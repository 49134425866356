<template>
  <b-card style="overflow-x: auto">
    <h2>Relatórios de Taxas e Parcelas</h2>
    <validation-observer ref="formPesquisar">
      <b-form>
        <b-row class="mt-2 mb-2">
          <b-col md="3">
            <b-form-group label="Data Inicial" label-for="dataInicial">
              <validation-provider #default="{ errors }" name="Data Inicial">
                <b-input-group>
                  <cleave
                    id="dataInicial"
                    type="text"
                    v-model="dataInicial"
                    placeholder="DD/MM/AAAA"
                    class="form-control cleave-height"
                    :options="options.cleaveDate"
                    :raw="false"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      size="sm"
                      button-variant="primary"
                      class="datePickerWithInput"
                      right
                      button-only
                      v-model="dataInicialDatePicker"
                      :hide-header="true"
                      :initial-date="new Date()"
                      :state="errors[0] ? false : null"
                      label-help=""
                      label-calendar="Calendário"
                      label-close-button="Fechar"
                      label-current-month="Mês atual"
                      label-next-decade="Próxima década"
                      label-next-month="Próximo mês"
                      label-next-year="Próximo ano"
                      label-prev-decade="Década anterior"
                      label-prev-month="Mês anterior"
                      label-prev-year="Ano anterior"
                      label-select-month="Selecione o mês"
                      label-select-year="Selecione o ano"
                      label-selected="Selecionado"
                      label-no-date-selected="Nenhuma data selecionada"
                      label-reset-button="Resetar"
                      label-today-button="Hoje"
                      label-today="Hoje"
                      locale="pt-br"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: 'numeric' }"
                      aria-controls="dataInicial"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Data Final" label-for="dataFinal">
              <validation-provider #default="{ errors }" name="Data Final">
                <b-input-group>
                  <cleave
                    id="dataFinal"
                    type="text"
                    v-model="dataFinal"
                    placeholder="DD/MM/AAAA"
                    class="form-control cleave-height"
                    :options="options.cleaveDate"
                    :raw="false"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      size="sm"
                      button-variant="primary"
                      class="datePickerWithInput"
                      right
                      button-only
                      v-model="dataFinalDatePicker"
                      :hide-header="true"
                      :initial-date="new Date()"
                      :state="errors[0] ? false : null"
                      label-help=""
                      label-calendar="Calendário"
                      label-close-button="Fechar"
                      label-current-month="Mês atual"
                      label-next-decade="Próxima década"
                      label-next-month="Próximo mês"
                      label-next-year="Próximo ano"
                      label-prev-decade="Década anterior"
                      label-prev-month="Mês anterior"
                      label-prev-year="Ano anterior"
                      label-select-month="Selecione o mês"
                      label-select-year="Selecione o ano"
                      label-selected="Selecionado"
                      label-no-date-selected="Nenhuma data selecionada"
                      label-reset-button="Resetar"
                      label-today-button="Hoje"
                      label-today="Hoje"
                      locale="pt-br"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: 'numeric' }"
                      aria-controls="dataFinal"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <b-table
      striped
      responsive
      class="position-relative"
      :per-page="pesquisar.perPage"
      :items="relatorios"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :busy="isBusy"
      :current-page="pesquisar.currentPage"
      foot-row-class="bold-footer-row"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Carregando...</strong>
        </div>
      </template>

      <template #cell(nome)="row">
        {{ row.item.nome }}
      </template>

      <template #cell(acoes)="row">
        <template>
          <div style="width: 10px">
            <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
              <template #button-content>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon"
                  title="Este botão contém as opções disponíveis para este relatório"
                >
                  <feather-icon icon="FileTextIcon" />
                </b-button>
              </template>

              <b-dropdown-item href="#" @click="buscarRelatorio(row.item.id)">
                <feather-icon icon="FileTextIcon" size="16" />
                <span class="align-middle ml-50">PDF</span>
              </b-dropdown-item>

              <b-dropdown-item href="#" @click="buscarRelatorioExcel(row.item.id)">
                <feather-icon icon="CheckCircleIcon" size="16" />
                <span class="align-middle ml-50" title="Exportar tabela para excel">Excel</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        style="align-items: center"
        label="Por Página"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select id="perPageSelect" v-model="pesquisar.perPage" size="sm" inline :options="pageOptions" />
      </b-form-group>

      <!-- pagination -->
      <b-pagination
        v-model="pesquisar.currentPage"
        :total-rows="totalRows"
        :per-page="pesquisar.perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>

    <!-- RELATÓRIOS PDF -->
    <div>
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="false"
        :paginate-elements-by-height="800"
        filename="Agenda Bank - Relatório"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="1100px"
        @beforeDownload="beforeDownload($event)"
        @hasDownloaded="hasDownloaded"
        @startPagination="startPagination"
        foot-row-class="bold-footer-row"
        ref="relatorio"
      >
        <section slot="pdf-content" style="background-color: white">
          <b-card class="fundoRelatorio cabecalhoTable">
            <div v-for="(linhaDaTabela, indice) in linhasTabela" :key="`tabelaItem_${indice}`">
              <div class="fundoRelatorio titulo">
                <div class="d-none d-lg-flex align-items-center fundoRelatorio">
                  <!-- <b-img fluid :src="logoImg" alt="Logo da Agenda"></b-img> -->

                  <img :src="logoAgenda" alt="Logo" />

                  <!-- <img fluid :src="logoA" alt="Logo" /> -->
                  <h2 class="brand-text text-primary ml-1" style="margin-bottom: 0px; margin-left: 0px !important">
                    Agenda Bank
                  </h2>
                </div>
                <div class="lineBlue"></div>
                <div class="lineGray"></div>
                <h1 class="fundoRelatorio instituto" style="margin-bottom: 0px; margin-top: 5px">
                  {{ userData.Instituto.nome }}
                </h1>
              </div>

              <!-- RELATÓRIO DE AMORTIZAÇÃO -->
              <div class="fundoRelatorio tituloRelatorio">{{ nomeRelatorio.nome }}</div>
              <div v-if="!relatorioDeCompetencia" class="periodoRelatorio fundoRelatorio">
                PERÍODO: {{ dataInicial }} até {{ dataFinal }}
              </div>
              <div v-else class="periodoRelatorio fundoRelatorio">
                COMPETÊNCIA:
                {{
                  !String(dataInicial).includes('/')
                    ? formatarCompetenciaDescricao(formatarCompetencia(dataInicial))
                    : formatarCompetenciaDescricao(formatarCompetencia(formatarDataGlobal(dataInicial)))
                }}
                até
                {{
                  !String(dataFinal).includes('/')
                    ? formatarCompetenciaDescricao(formatarCompetencia(dataFinal))
                    : formatarCompetenciaDescricao(formatarCompetencia(formatarDataGlobal(dataFinal)))
                }}
              </div>

              <b-table
                striped
                responsive
                class="RelatorioJurosPDF"
                :class="linhasTabela.length - 1 == indice ? 'UltimaTable' : ''"
                style="text-align: center"
                id="tableTaxaJurosPDF"
                :head-variant="'light'"
                :items="linhaDaTabela"
                :fields="colunasTabelaRelatorio"
              >
              </b-table>

              <div
                v-if="!relatorioDeCompetencia && linhasTabela.length - 1 == indice && linhaDaTabela.length == 22"
                class="html2pdf__page-break"
              />
              <div :class="linhaDaTabela.length == 22 ? 'margemTotalizador' : ''">
                <div v-if="!relatorioDeCompetencia && linhasTabela.length - 1 == indice" class="fundoRelatorio totalParcelas">
                  TOTAL DE PARCELAS: {{ itemsRelatorio.totalParcelas }}
                </div>
                <div v-if="!relatorioDeCompetencia && linhasTabela.length - 1 == indice" class="fundoRelatorio totalParcelas">
                  TOTAL DE CONTRATOS: {{ itemsRelatorio.totalContratos }}
                </div>
                <div v-if="!relatorioDeCompetencia && linhasTabela.length - 1 == indice" class="fundoRelatorio totalParcelas">
                  TOTAL DE SEGURADOS: {{ itemsRelatorio.totalSegurados }}
                </div>
                <div v-if="relatorioDeCompetencia && linhasTabela.length - 1 == indice" class="fundoRelatorio totalParcelas">
                  {{ itemsRelatorio.totalAcumulado ? 'TOTAL ACUMULADO ATÉ O PERÍODO' : '' }} {{ itemsRelatorio.totalAcumulado }}
                </div>
              </div>
              <div v-if="linhasTabela.length - 1 != indice" class="html2pdf__page-break" />
            </div>
          </b-card>
        </section>
      </vue-html2pdf>
    </div>
  </b-card>
</template>

<script>
  import Cleave from 'vue-cleave-component'
  import useJwt from '@/auth/jwt/useJwt'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import Ripple from 'vue-ripple-directive'
  import {
    formatarData,
    formatarTipoDate,
    validarData,
    formatarCompetencia,
    formatarDataGlobal,
    formatarCompetenciaDescricao,
  } from '@/libs/utils'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import VueHtml2pdf from 'vue-html2pdf'
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import pdfMake from 'pdfmake/build/pdfmake'
  import pdfFonts from 'pdfmake/build/vfs_fonts'
  import parcelasRecebidasPorCompetencia from './parcelasRecebidasPorCompetencia'
  import parcelasRecebidasPorPeriodo from './parcelasRecebidasPorPeriodo'
  import rentabilidadePorCompetencia from './rentabilidadePorCompetencia'
  import relatorioTaxaJuros from './relatorioTaxaJuros'
  import * as dateFns from 'date-fns'

  export default {
    components: {
      BCardCode,
      Cleave,
      ToastificationContent,
      ValidationProvider,
      ValidationObserver,
      VueHtml2pdf,
      pdfMake,
      pdfFonts,
      validarData,
      formatarTipoDate,
      formatarCompetencia,
      formatarCompetenciaDescricao,
      parcelasRecebidasPorPeriodo,
      rentabilidadePorCompetencia,
      relatorioTaxaJuros,
      parcelasRecebidasPorCompetencia,
      formatarDataGlobal,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        aImg: require('@/assets/images/logo/Alogo2.png'),
        // Relatórios
        options: {
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
        },
        dataInicialDatePicker: '',
        dataFinalDatePicker: '',
        dataInicial: '',
        dataFinal: '',
        parcelasRecebidasPorCompetencia,
        parcelasRecebidasPorPeriodo,
        rentabilidadePorCompetencia,
        relatorioTaxaJuros,
        formatarCompetenciaDescricao,
        formatarCompetencia,
        formatarDataGlobal,
        htmlPdfOptions: {
          margin: [0, 0, 0.35, 0],
          html2canvas: {
            scale: 1,
            useCORS: true,
          },
          jsPDF: {
            unit: 'in',
            format: 'a4',
            orientation: 'landscape',
          },
        },
        filtro: {},
        imagemLogo: '',
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        required,
        dados: {
          dataInicial: '',
          dataFinal: '',
          tipoDePesquisa: 'periodo',
        },
        pesquisar: {
          tipoOpcoes: 1,
          descricao: '',
          perPage: 10,
          currentPage: 1,
          dataInicial: '',
          dataFinal: '',
        },
        cleaveDate: {
          date: true,
          delimiter: '/',
          datePattern: ['d', 'm', 'Y'],
        },

        competenciaOpcoes: [],

        optionsTipoDePesquisa: [
          { text: 'Período', value: 'periodo' },
          { text: 'Competência', value: 'competencia' },
        ],

        relatorioDeCompetencia: null,
        isBusy: false,
        acesso: {},
        pageOptions: [10, 25, 50, 100],
        totalRows: 1,
        NaoGeradoGRPE: 1,
        GeradoGRPE: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        fields: [
          { key: 'nome', label: 'TIPO DE RELATÓRIO' },
          { key: 'acoes', label: 'AÇÕES' },
        ],
        relatorios: [
          { nome: 'RELATÓRIO DE AMORTIZAÇÃO', id: 'amortizacao' },
          { nome: 'RELATÓRIO DE TAXA DE OSCILAÇÃO DE RISCOS', id: 'oscilacaoDeRiscos' },
          { nome: 'RELATÓRIO DE TAXA DE RENTABILIDADE', id: 'rentabilidadeConsolidado' },
          { nome: 'RELATÓRIO DE TAXA DE FUNDO GARANTIDOR', id: 'fundoGarantido' },
          { nome: 'RELATÓRIO DE TAXA DE ADMINISTRAÇÃO', id: 'taxaAdministracaoConsolidado' },
          { nome: 'RELATÓRIO CONSOLIDADO DE PARCELAS', id: 'parcelasConsolidadas' },
          { nome: 'RELATÓRIO CONSOLIDADO DE PARCELAS RECEBIDAS', id: 'parcelasRecebidasPorCompetencia' },
          { nome: 'RELATÓRIO CONSOLIDADO DE RENTABILIDADE', id: 'rentabilidadePorCompetencia' },
        ],
        linhasTabela: [],
        itemsRelatorio: {},
        colunasTabelaRelatorio: [],
        nomeRelatorio: '',
      }
    },
    computed: {
      logoAgenda() {
        return this.userData.Instituto?.logoRelatorio?.logo ?? this.aImg
      },
    },
    mounted() {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.dados.tipoDePesquisa = 'periodo'
      this.getCompetencias()

      this.dataInicial = '0101' + new Date().getFullYear().toString()
      let ultimoDiaDoMes = dateFns.lastDayOfMonth(new Date())
      this.dataFinal = dateFns.format(ultimoDiaDoMes, 'ddMMyyyy')

      this.dados.competenciaInicial = 'Janeiro/' + new Date().getFullYear().toString()
      this.dados.competenciaFinal = 'Dezembro/' + new Date().getFullYear().toString()
    },
    methods: {
      startPagination() {
        this.isBusy = true
      },
      hasDownloaded() {
        this.isBusy = false
      },
      boldRowClass(item, type) {
        return item.negrito ? 'font-weight-bold' : ''
      },
      isDateValid(data) {
        return validarData(data)
      },
      converteMeses(inicial = 'Novembro/2023', final = 'Novembro/2023') {
        const meses = [
          'janeiro',
          'fevereiro',
          'março',
          'abril',
          'maio',
          'junho',
          'julho',
          'agosto',
          'setembro',
          'outubro',
          'novembro',
          'dezembro',
        ]
        const [inicialMes, inicialAno] = inicial.split('/')
        const [finalMes, finalAno] = final.split('/')
        const inicialDate = new Date(`${meses.indexOf(inicialMes.toLowerCase()) + 1} 1, ${inicialAno} 00:00:00`)
        const finalDate = new Date(`${meses.indexOf(finalMes.toLowerCase()) + 1} 1, ${finalAno} 00:00:00`)
        finalDate.setMonth(finalDate.getMonth() + 1)
        finalDate.setDate(0)
        return {
          dataInicial: inicialDate.toISOString(),
          dataFinal: finalDate.toISOString(),
        }
      },
      retornaData(initial) {
        const { dataInicial, dataFinal } = this.converteMeses(this.dados.competenciaInicial, this.dados.competenciaFinal)

        if (initial) {
          if (this.dados.tipoDePesquisa == 'periodo') {
            return formatarTipoDate(this.dataInicial)
          } else {
            return dataInicial
          }
        } else {
          if (this.dados.tipoDePesquisa == 'periodo') {
            return formatarTipoDate(this.dataFinal)
          } else {
            return dataFinal
          }
        }
      },
      buscarRelatorio(relatorio) {
        this.isBusy = true
        this.nomeRelatorio = this.relatorios.find((item) => item.id === relatorio)
        this.$refs.formPesquisar.validate().then((success) => {
          if (this.isDateValid(this.dataInicial) && this.isDateValid(this.dataFinal)) {
            this.pesquisar = {
              ...this.pesquisar,
              tipoDePesquisa: this.tipoDePesquisa,
              dataInicial: this.retornaData(true),
              dataFinal: this.retornaData(false),
              formatar: true,
            }

            useJwt
              .pesquisar(`relatorio/${relatorio}`, {
                tipoDePesquisa: this.tipoDePesquisa,
                ...this.pesquisar,
                institutoSelecionado: this.userData.institutoSelecionado,
              })
              .then((response) => {
                this.isBusy = false
                if (response.data.status == 'erro') {
                  this.$message.error(response.data.mensagem)
                  return
                }

                this.linhasTabela = response.data.linhas

                this.dividirLista(this.linhasTabela, 22)
                this.itemsRelatorio = response.data
                this.colunasTabelaRelatorio = response.data.colunas

                this.relatorioDeCompetencia = false

                this.$refs.relatorio.generatePdf()
              })
              .catch((error) => {
                console.error(error)
              })
          }
        })
      },
      buscarRelatorioExcel(relatorio) {
        this.nomeRelatorio = this.relatorios.find((item) => item.id === relatorio)
        this.$refs.formPesquisar.validate().then((success) => {
          if (this.isDateValid(this.dataInicial) && this.isDateValid(this.dataFinal)) {
            this.isBusy = true
            this.pesquisar = {
              ...this.pesquisar,
              dataInicial: this.retornaData(true),
              dataFinal: this.retornaData(false),
              formatar: true,
            }

            useJwt
              .pesquisar(`relatorio/${relatorio}`, {
                tipoDePesquisa: this.tipoDePesquisa,
                ...this.pesquisar,
                institutoSelecionado: this.userData.institutoSelecionado,
              })
              .then((response) => {
                this.isBusy = false
                if (response.data.status == 'erro') {
                  this.$message.error(response.data.mensagem)
                  return
                }

                this.linhasTabela = response.data.linhas

                this.dividirLista(this.linhasTabela, 22)
                this.itemsRelatorio = response.data
                this.colunasTabelaRelatorio = response.data.colunas

                this.relatorioDeCompetencia = false

                this.exportToExcel()
              })
              .catch((error) => {
                console.error(error)
              })
          }
        })
      },
      exportToExcel() {
        // Nome do arquivo CSV
        const filename = 'Relatório.csv'

        // Preparar dados para CSV
        let csv = this.colunasTabelaRelatorio.reduce((accumulator, currentValue, indice) => {
          let cabecalho
          if (indice == 0) {
            cabecalho = currentValue.label
          } else {
            cabecalho = accumulator + ';' + currentValue.label
          }

          return cabecalho
        }, '')

        csv += '\n'

        let linhas = []
        this.linhasTabela.forEach((itens) => {
          itens.map((colunas) => {
            linhas.push(colunas)
          })
        })

        linhas.forEach((linha) => {
          // Remove o totalizador
          let linhaCsv = ''
          for (let propriedade in linha) {
            if (!String(linha[propriedade]).includes('VALOR TOTAL')) linhaCsv += linha[propriedade] + ';'
            else break
          }
          linhaCsv += '\n'
          csv += linhaCsv
          // csv += `${linha.nomeCompleto};${linha.matricula};${linha.numeroContrato};${linha.taxaDoEmprestimoAoMes};${linha.valor};${linha.amortizacao};${linha.rentabilidade};${linha.administracao};${linha.fundoGarantidor};${linha.oscilacaoRiscos}\n`;
        })

        // Criar um objeto Blob com os dados CSV
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })

        // Criar um URL do Blob
        const url = window.URL.createObjectURL(blob)

        // Criar um elemento "a" para download do arquivo
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = filename

        // Adicionar o elemento "a" à página e clicar nele para iniciar o download
        document.body.appendChild(a)
        a.click()

        // Limpar o objeto URL e remover o elemento "a"
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      },
      dividirLista(listaOriginal, tamanhoMaximo) {
        let primeiraPagina = listaOriginal.slice(0, tamanhoMaximo)
        const restante = listaOriginal.slice(tamanhoMaximo)
        const sublistas = [primeiraPagina]
        let sublistaAtual = []

        restante.forEach((item, indice) => {
          if (sublistaAtual.length < tamanhoMaximo) {
            sublistaAtual.push(item)
          } else {
            sublistas.push(sublistaAtual)
            sublistaAtual = [item]
          }

          if (indice === restante.length - 1) {
            sublistas.push(sublistaAtual)
          }
        })

        this.linhasTabela = sublistas
      },
      async beforeDownload({ html2pdf, options, pdfContent }) {
        await html2pdf()
          .set(options)
          .from(pdfContent)
          .toPdf()
          .get('pdf')
          .then((pdf) => {
            const totalPages = pdf.internal.getNumberOfPages()

            for (let pagina = 1; pagina <= totalPages; pagina++) {
              pdf.setPage(pagina)
              pdf.setFontSize(10)
              pdf.setTextColor(150)

              pdf.text(
                'Pagina ' + pagina + ' de ' + totalPages,
                pdf.internal.pageSize.getWidth() * 0.88,
                pdf.internal.pageSize.getHeight() - 0.15,
              )

              pdf.setFontSize(7)
              pdf.text(
                `${this.userData.Instituto.logradouro}, ${this.userData.Instituto.numero}, ${this.userData.Instituto.bairro}, ${this.userData.Instituto.cep} - ${this.userData.Instituto.cidade} - ${this.userData.Instituto.uf} E-mail: ${this.userData.Instituto.emailResponsavel} - Fone:${this.userData.Instituto.telefone}`,
                pdf.internal.pageSize.getWidth() * 0.03,
                pdf.internal.pageSize.getHeight() - 0.15,
              )
            }
          })
          .save()

        this.isBusy = false
      },
      async getCompetencias() {
        const { nova, velha } = (await useJwt.pesquisar('relatorio/ultima-parcela')).data

        // const meses = [
        //   "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
        //   "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
        // ];

        const dataAtual = new Date()
        const listaCompetencias = []
        // let anoAtual = anoInicial
        // let mesAtual = dataAtual.getMonth();

        let meses = []
        let mes = nova % 100
        let ano = Math.floor(nova / 100)
        let mesVelha = velha % 100
        let anoVelha = Math.floor(velha / 100)
        const monthNames = [
          'Janeiro',
          'Fevereiro',
          'Março',
          'Abril',
          'Maio',
          'Junho',
          'Julho',
          'Agosto',
          'Setembro',
          'Outubro',
          'Novembro',
          'Dezembro',
        ]
        while (ano < anoVelha || (ano === anoVelha && mes <= mesVelha)) {
          meses.push(monthNames[mes - 1] + '/' + ano)
          mes++
          if (mes > 12) {
            mes = 1
            ano++
          }

          listaCompetencias.push(monthNames[mes - 1] + '/' + ano)
        }

        // while((anoAtual * 100) + mesAtual <= velha) {
        //   const mesAtual = dataAtual.getMonth();

        //   const mesCompetencia = meses[mesAtual];
        //   const competencia = `${mesCompetencia}/${anoAtual}`;
        //   listaCompetencias.push(competencia);

        //   // Avança para o mês anterior
        //   dataAtual.setMonth(mesAtual + 1);

        //   // Caso o mês atual seja janeiro, ajusta o ano
        //   if (mesAtual === 0) {
        //     dataAtual.setFullYear(anoAtual + 1);
        //   }
        //   anoAtual += 1
        // }

        this.competenciaOpcoes = listaCompetencias
        this.filtro.competencia = listaCompetencias[0]
      },
    },
    watch: {
      dataInicialDatePicker(newVal) {
        this.dataInicial = formatarData(this.dataInicialDatePicker)
      },
      dataFinalDatePicker(newVal) {
        this.dataFinal = formatarData(this.dataFinalDatePicker)
      },
    },
  }
</script>

<style>
  .fundoRelatorio {
    background-color: white;
    word-break: break-word;
  }

  .titulo {
    background-color: inherit !important;
    justify-content: flex-start;
    word-break: break-all;
    padding-top: 5px;
  }

  .RelatorioJurosPDF .thead-light th {
    background-color: #f3f2f700 !important;
    border-color: #4941a6;
    border-block-start: initial;
    color: #4941a6;
    font-size: 10px;
    padding: 10.5px 0;
    inline-size: max-content;
  }

  .lineBlue {
    width: 288px;
    height: 13px;
    background-color: #4941a6;
  }
  .lineGray {
    width: 563px;
    height: 13px;
    background-color: #b8c2cb;
  }
  .instituto {
    font-size: initial;
    padding-left: 20px;
  }
  .tituloRelatorio {
    text-align: center;
    font-weight: bold;
    font-size: large;
    color: #615771;
  }
  .cabecalhoTable {
    margin-bottom: 0px;
    font-size: 9px;
    word-wrap: normal;
    white-space: pre-wrap;
  }

  .periodoRelatorio {
    font-weight: bold;
    color: black;
    text-align: center;
  }

  .totalParcelas {
    font-weight: bold;
    color: black;
    font-size: 9px;
    padding-bottom: 2px;
    padding-left: 20px;
  }

  .rodapeRelatorio {
    font-size: 11px;
    margin-bottom: 0px;
    color: #736c81;
    margin-top: 5px;
    padding-left: 20px;
  }

  .rodapeRelatorioContainer {
    bottom: 0rem;
  }

  .content-wrapper {
    position: relative;
  }

  .bold-footer-row {
    font-weight: bold;
  }

  #tableTaxaJurosPDF thead tr th {
    width: 100px;
  }

  #tableTaxaJurosPDF tbody tr td {
    padding: 3px !important;
  }

  .UltimaTable tbody tr:last-child {
    font-weight: bold;
  }

  #logoAgenda {
    margin: 0 10px;
  }

  div.vue-html2pdf div.card-body {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .RelatorioJurosPDF {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .margemTotalizador {
    margin-top: 30px;
  }

  .RelatorioJurosPDF tbody tr td {
    text-wrap: nowrap;
  }

  .mt-07 {
    margin-top: 0.7rem !important;
  }

  .mt-05 {
    margin-top: 0.5rem !important;
  }

  #radiosTipoDePesquisa > label {
    width: 0.5em;
  }
</style>
