function mapTableBodies(innerTableCell) {
  const findInlineHeight = this.findInlineHeight(innerTableCell, maxWidth, usedWidth)

  usedWidth = findInlineHeight.width
  return findInlineHeight.height
}

function findInlineHeight(cell, maxWidth, usedWidth = 0) {
  let calcLines = (inlines) => {
    if (!inlines)
      return {
        height: 0,
        width: 0,
      }
    let currentMaxHeight = 0
    let lastHadLineEnd = false
    for (const currentNode of inlines) {
      usedWidth += currentNode.width
      if (usedWidth > maxWidth || lastHadLineEnd) {
        currentMaxHeight += currentNode.height
        usedWidth = currentNode.width
      } else {
        currentMaxHeight = Math.max(currentNode.height, currentMaxHeight)
      }
      lastHadLineEnd = !!currentNode.lineEnd
    }
    return {
      height: currentMaxHeight,
      width: usedWidth,
    }
  }
  if (cell._offsets) {
    usedWidth += cell._offsets.total
  }
  if (cell._inlines && cell._inlines.length) {
    return calcLines(cell._inlines)
  } else if (cell.stack && cell.stack[0]) {
    return cell.stack
      .map((item) => {
        return findInlineHeight(item, maxWidth)
      })
      .reduce((prev, next) => {
        return {
          height: prev.height + next.height,
          width: Math.max(prev.width + next.width),
        }
      })
  } else if (cell.table) {
    let currentMaxHeight = 0
    for (const currentTableBodies of cell.table.body) {
      const innerTableHeights = currentTableBodies.map(mapTableBodies)
      currentMaxHeight = Math.max(...innerTableHeights, currentMaxHeight)
    }
    return {
      height: currentMaxHeight,
      width: usedWidth,
    }
  } else if (cell._height) {
    usedWidth += cell._width
    return {
      height: cell._height,
      width: usedWidth,
    }
  }

  return {
    height: null,
    width: usedWidth,
  }
}

export default function applyVerticalAlignment(node, rowIndex, align, manualHeight = 0) {
  // New default argument
  const allCellHeights = node.table.body[rowIndex].map((innerNode, columnIndex) => {
    if (innerNode._span) return null
    const calcWidth = [...Array(innerNode.colSpan || 1).keys()].reduce((acc, i) => {
      return acc + node.table.widths[columnIndex + i]._calcWidth
    }, 0)
    const mFindInlineHeight = findInlineHeight(innerNode, calcWidth, 0)
    return mFindInlineHeight.height
  })
  const maxRowHeight = manualHeight ? manualHeight[rowIndex] : Math.max(...allCellHeights) // handle manual height
  node.table.body[rowIndex].forEach((cell, ci) => {
    if (allCellHeights[ci] && maxRowHeight > allCellHeights[ci]) {
      let topMargin

      let cellAlign = align
      if (Array.isArray(align)) {
        cellAlign = align[ci]
      }

      if (cellAlign === 'bottom') {
        topMargin = maxRowHeight - allCellHeights[ci]
      } else if (cellAlign === 'center') {
        topMargin = (maxRowHeight - allCellHeights[ci]) / 2
      }

      if (topMargin) {
        if (cell._margin) {
          cell._margin[1] = topMargin
        } else {
          cell._margin = [0, topMargin, 0, 0]
        }
      }
    }
  })
}
