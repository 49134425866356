<template>
  <div>
    <b-card>
      <h2>Filtros</h2>
      <validation-observer ref="formPesquisar">
        <b-form class="mt-2">
          <b-row>
            <b-col cols="2">
              <b-form-group label="Competência Inicial" label-for="competenciaInicial">
                <validation-provider #default="{ errors }" name="Competência Inicial" rules="required">
                  <date-picker
                    v-model="filtro.competenciaInicial"
                    format="MM/YYYY"
                    class="w-100 h-100 input-group-merge form-control-merge"
                    type="month"
                    value-type="date"
                    lang="pt-br"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-form-group label="Competência Final" label-for="competenciaFinal" rules="required">
                <validation-provider #default="{ errors }" name="Competência Final" rules="required">
                  <date-picker
                    v-model="filtro.competenciaFinal"
                    format="MM/YYYY"
                    class="w-100 h-100 input-group-merge form-control-merge"
                    type="month"
                    value-type="date"
                    lang="pt-br"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="5">
              <b-form-group label="Órgão" label-for="orgao">
                <v-select-pt
                  id="orgao"
                  name="orgao"
                  style="max-height: 34px !important"
                  v-model="filtro.orgao"
                  :options="orgaoOpcao"
                  :reduce="(option) => option.id"
                  @option:selected="carregarGrid()"
                  label="nome"
                  v-on:keydown.enter="carregarGrid"
                >
                </v-select-pt>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="justify-content-end">
            <b-col md="auto">
              <b-button
                :disabled="isBusy"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                style="margin-top: 21px; width: 160px"
                @click="carregarGrid()"
              >
                {{ !isBusy ? 'Prévia' : '' }}
                <b-spinner small v-if="isBusy" />
              </b-button>
            </b-col>
            <b-col md="auto">
              <template>
                <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
                  <template #button-content>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      style="margin-top: 21px; width: 160px"
                    >
                      Exportar
                    </b-button>
                  </template>
                  <b-dropdown-item href="#" @click="exportToPDF()">
                    <feather-icon icon="FileTextIcon" size="16" />
                    <span class="align-middle ml-50" title="Exportar para PDF">PDF</span>
                  </b-dropdown-item>
                  <b-dropdown-item href="#" @click="exportToExcel()">
                    <feather-icon icon="CheckCircleIcon" size="16" />
                    <span class="align-middle ml-50" title="Exportar para excel">Excel</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <!-- TABELA -->
    <b-card v-if="this.itemsTabela.length > 0 || isBusy">
      <template v-if="isBusy">
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Carregando Tabela...</strong>
        </div>
      </template>
      <div v-if="!isBusy" v-show="this.itemsTabela.length > 0">
        <b-row>
          <b-col md="12">
            <!-- GRID -->
            <div ref="tabelaGrid" :itemsTabela="this.itemsTabela">
              <b-table
                style="overflow-x: auto; -webkit-overflow-scrolling: touch"
                outlined
                id="tabelaGrid"
                ref="tabelaGrid"
                striped
                :per-page="perPage"
                :current-page="currentPage"
                :fields="this.currentFields"
                :items="this.itemsTabela"
                responsive="sm"
                class="position-relative custom-rounded-table"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :row-details="(row) => row.item.detailsShown"
                foot-clone
              >
                <template #foot(competencia)>
                  <span class="text-bold">Valor total:</span>
                </template>
                <template #foot(administracao)>
                  <span class="text-bold">{{ totalValorAdministracao }}</span>
                </template>
                <template #foot(amortizacao)>
                  <span class="text-bold">{{ totalAmortizacao }}</span>
                </template>
                <template #foot(oscilacaoRiscos)>
                  <span class="text-bold">{{ totalOscilacaoRiscos }}</span>
                </template>
                <template #foot(fundoGarantidor)>
                  <span class="text-bold">{{ totalFundoGarantidor }}</span>
                </template>
                <template #foot(rentabilidade)>
                  <span class="text-bold">{{ totalRentabilidade }}</span>
                </template>
              </b-table>
              <b-card-body v-if="this.itemsTabela.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
                <!-- page length -->
                <b-form-group
                  style="align-items: center"
                  label="Por Página"
                  label-cols="6"
                  label-align="left"
                  label-size="sm"
                  label-for="sortBySelect"
                  class="text-nowrap mb-md-0 mr-1"
                >
                  <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
                </b-form-group>

                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="this.itemsTabela.length"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-card-body>
            </div>
          </b-col>
        </b-row>
      </div>
      <div>
        <RelatorioTaxaFundoGarantidorRetrato ref="exportarPDF" :parametro="parametroPDF" id="exportarPDF" />
      </div>
    </b-card>
  </div>
</template>
<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required, dataPassada, valor } from '@validations'
  import {
    formatarData,
    formatarValorReais,
    formatarValor,
    formatarCompetenciaDescricao,
    formatarParaNumber,
  } from '@/libs/utils'
  import Cleave from 'vue-cleave-component'
  import 'cleave.js/dist/addons/cleave-phone.br'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import RelatorioTaxaFundoGarantidorRetrato from './Components/RelatorioTaxaFundoGarantidorRetrato.vue'
  import { VueSelect } from 'vue-select'
  import { generateAndDownloadPdf } from '@/utils/geradorPDF/geradorPDF.js'
  import VueHtml2pdf from 'vue-html2pdf'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import * as XLSX from 'xlsx'
  export default {
    components: {
      valor,
      VueHtml2pdf,
      ToastificationContent,
      formatarValorReais,
      formatarValor,
      generateAndDownloadPdf,
      RelatorioTaxaFundoGarantidorRetrato,
      useJwt,
      dataPassada,
      formatarData,
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      Cleave,
      VueSelect,
      required,
      formatarParaNumber,
      formatarCompetenciaDescricao,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        itemsTabela: [],
        valor: 0,
        isBusy: false,
        nomeRelatorio: 'Relatório Consolidado de Parcelas Recebidas',
        parametroPDF: {
          dados: [],
          colunas: [],
          detalhamentoFields: [],
        },
        linhasTabela: [],
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 1,
        sortBy: 'codigo',
        sortDesc: true,
        sortDirection: 'desc',
        options: {
          cleaveCompetencia: {
            numericOnly: true,
            delimiters: ['/'],
            blocks: [2, 4],
          },
        },
        number: {},
        formatarCompetenciaDescricao,
        colunasTabelas: [],
        currentFields: [
          {
            key: 'competencia',
            label: 'Competência',
            formatter: (value) => formatarCompetenciaDescricao(value),
          },
          {
            key: 'administracao',
            label: 'Valor de Administração',
          },
          {
            key: 'amortizacao',
            label: 'Amortização',
          },
          {
            key: 'oscilacaoRiscos',
            label: 'Oscilação de Riscos',
          },
          {
            key: 'fundoGarantidor',
            label: 'Fundo Garantidor',
          },
          {
            key: 'rentabilidade',
            label: 'Rentabilidade',
          },
        ],
        anoOpcoes: [
          { value: 2021, text: '2021' },
          { value: 2022, text: '2022' },
          { value: 2023, text: '2023' },
          { value: 2024, text: '2024' },
          { value: 2025, text: '2025' },
          { value: 2026, text: '2026' },
          { value: 2027, text: '2027' },
          { value: 2028, text: '2028' },
          { value: 2029, text: '2029' },
          { value: 2030, text: '2030' },
        ],
        mesOpcoes: [
          { value: 1, text: 'Janeiro' },
          { value: 2, text: 'Fevereiro' },
          { value: 3, text: 'Março' },
          { value: 4, text: 'Abril' },
          { value: 5, text: 'Maio' },
          { value: 6, text: 'Junho' },
          { value: 7, text: 'Julho' },
          { value: 8, text: 'Agosto' },
          { value: 9, text: 'Setembro' },
          { value: 10, text: 'Outubro' },
          { value: 11, text: 'Novembro' },
          { value: 12, text: 'Dezembro' },
        ],
        filtro: {
          competenciaInicial: '',
          competenciaFinal: '',
        },
        orgaoOpcao: [],
      }
    },
    mounted() {
      this.configurarPagina()
    },
    methods: {
      configurarPagina() {
        // Configuração de filtro de data
        this.filtro.competenciaInicial = new Date()
        this.filtro.competenciaFinal = new Date()

        // Configuração de usuário
        if (this.userData.role !== 'master') {
          this.institutoId = this.userData.institutoId
        } else {
          this.institutoId = this.userData.institutoSelecionado
        }

        // Carregar órgãos
        this.carregarOrgaos()
      },
      carregarGrid() {
        if (!this.filtro.competenciaInicial || !this.filtro.competenciaFinal) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Atenção!',
                text: 'Preencha os campos obrigatórios!',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
          this.itemsTabela.length = 0
          return
        }
        this.isBusy = true

        let filtro = {
          competenciaInicial: this.filtro.competenciaInicial,
          competenciaFinal: this.filtro.competenciaFinal,
          institutoSelecionado: this.institutoId,
          orgaoId: this.filtro.orgao,
        }

        useJwt
          .post('relatorio/parcelasRecebidasPorCompetencia', filtro)
          .then((response) => {
            if (response.data.error || !response.data || response.data.status === 'erro') {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Alerta!',
                    text: 'Não há dados para visualizar!',
                    icon: 'BellIcon',
                    variant: 'warning',
                  },
                },
                {
                  position: 'top-right',
                  timeout: 8000,
                },
              )
              this.itemsTabela.length = 0
              return
            }

            this.itemsTabela = response.data.map((item) => ({
              ...item,
              amortizacao: formatarValorReais(item.amortizacao),
              oscilacaoRiscos: formatarValorReais(item.oscilacaoRiscos),
              fundoGarantidor: formatarValorReais(item.fundoGarantidor),
              rentabilidade: formatarValorReais(item.rentabilidade),
              administracao: formatarValorReais(item.administracao),
            }))

            this.linhasTabela = this.itemsTabela

            this.totalRows = this.itemsTabela.length
            this.colunasTabelas = this.currentFields.map((item) => ({
              label: item.label,
              key: item.key,
            }))

            this.totalRentabilidade = formatarValorReais(
              this.linhasTabela.reduce((acc, item) => acc + formatarParaNumber(item.rentabilidade), 0),
            )
            this.totalAmortizacao = formatarValorReais(
              this.linhasTabela.reduce((acc, item) => acc + formatarParaNumber(item.amortizacao), 0),
            )
            this.totalOscilacaoRiscos = formatarValorReais(
              this.linhasTabela.reduce((acc, item) => acc + formatarParaNumber(item.oscilacaoRiscos), 0),
            )
            this.totalFundoGarantidor = formatarValorReais(
              this.linhasTabela.reduce((acc, item) => acc + formatarParaNumber(item.fundoGarantidor), 0),
            )
            this.totalValorAdministracao = formatarValorReais(
              this.linhasTabela.reduce((acc, item) => acc + formatarParaNumber(item.administracao), 0),
            )

            let colunasPDF = [
              {
                key: 'competencia',
                label: 'Competência',
              },
              {
                key: 'administracao',
                label: 'Valor de Administração',
              },
              {
                key: 'amortizacao',
                label: 'Amortização',
              },
              {
                key: 'oscilacaoRiscos',
                label: 'Oscilação de Riscos',
              },
              {
                key: 'fundoGarantidor',
                label: 'Fundo Garantidor',
              },
              {
                key: 'rentabilidade',
                label: 'Rentabilidade',
              },
            ]

            const itemsPDF = this.linhasTabela.map((item) => {
              return {
                ...item,
              }
            })
            const totalizadores = {
              totalIndiceAtualizacao: this.totalIndiceAtualizacao,
              totalJurosAtuarial: this.totalJurosAtuarial,
              totalTaxaDeOportunidade: this.totalTaxaDeOportunidade,
              totalRentabilidade: this.totalRentabilidade,
              totalAmortacao: this.totalAmortacao,
              totalOscilacaoRiscos: this.totalOscilacaoRiscos,
              totalFundoGarantidor: this.totalFundoGarantidor,
            }

            // Monta variável global de relatório PDF
            this.parametroPDF = {
              dados: itemsPDF,
              colunas: colunasPDF,
              totalizadores: totalizadores,
            }
          })
          .catch((error) => {
            console.error(error)
            this.isBusy = false
          })
          .finally(() => {
            this.isBusy = false
          })
      },
      exportToPDF() {
        if (!this.itemsTabela.length > 0) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Alerta!',
                text: 'Não há dados para visualizar!',
                icon: 'BellIcon',
                variant: 'warning',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
          return
        }
        if (this.$refs.tabelaGrid) {
          let htmlContent = this.$refs.exportarPDF.$children[0].$refs.html2Pdfportrait.$el.innerHTML
          let relatorioPaisagem = false

          let periodoFiltro = `Período: ${this.dataInicial} a ${this.dataFinal}`
          let nomeRelatorio = this.nomeRelatorio

          generateAndDownloadPdf(this, htmlContent, periodoFiltro, nomeRelatorio, relatorioPaisagem)
        } else {
          console.error('Elemento da tabela não encontrado')
        }
      },
      exportToExcel() {
        if (!this.itemsTabela.length) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Alerta!',
                text: 'Não há dados para visualizar!',
                icon: 'BellIcon',
                variant: 'warning',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
          return
        }
        const data = this.linhasTabela.map((item) => ({
          Competência: item.competencia,
          Administração: item.administracao,
          Amortização: item.amortizacao,
          'Oscilação de Riscos': item.oscilacaoRiscos,
          'Fundo Garantidor': item.fundoGarantidor,
          Rentabilidade: item.rentabilidade,
        }))

        this.totalRentabilidade = formatarValorReais(
          this.linhasTabela.reduce((acc, item) => acc + formatarParaNumber(item.rentabilidade), 0),
        )
        this.totalAmortizacao = formatarValorReais(
          this.linhasTabela.reduce((acc, item) => acc + formatarParaNumber(item.amortizacao), 0),
        )
        this.totalOscilacaoRiscos = formatarValorReais(
          this.linhasTabela.reduce((acc, item) => acc + formatarParaNumber(item.oscilacaoRiscos), 0),
        )
        this.totalFundoGarantidor = formatarValorReais(
          this.linhasTabela.reduce((acc, item) => acc + formatarParaNumber(item.fundoGarantidor), 0),
        )
        this.totalValorAdministracao = formatarValorReais(
          this.linhasTabela.reduce((acc, item) => acc + formatarParaNumber(item.administracao), 0),
        )

        data.push({
          Competência: 'Total:',
          Administração: this.totalValorAdministracao,
          Amortização: this.totalAmortacao,
          'Oscilação de Riscos': this.totalOscilacaoRiscos,
          'Fundo Garantidor': this.totalFundoGarantidor,
          Rentabilidade: this.totalRentabilidade,
        })

        const ws = XLSX.utils.json_to_sheet(data)

        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Relatorio')

        // Salvando o arquivo
        XLSX.writeFile(wb, 'Relatorio.xlsx')
      },
      carregarOrgaos() {
        if (this.userData.institutoSelecionado) {
          useJwt
            .get(`cadastro/orgao/buscaPorInstitutoId/${this.userData.institutoSelecionado}`)
            .then((response) => {
              this.orgaoOpcao = response.data
              this.orgaoOpcao.unshift({ id: '', nome: 'TODOS' })
            })
            .catch((error) => {
              console.error(error)
            })
        }
      },
    },
  }
</script>
<style>
  .mx-calendar-content .cell.active {
    background-color: #016b3a !important;
  }
  .mx-calendar-content .cell:hover {
    background-color: #7886d1 !important;
    color: white !important;
  }

  #orgao [role='combobox'] {
    max-height: 34px !important;
  }
</style>
