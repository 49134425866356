<template>
  <div>
    <!-- FILTROS -->
    <b-card>
      <h2>Filtros</h2>
      <validation-observer ref="formPesquisar">
        <b-form class="mt-2">
          <b-row>
            <b-col md="2">
              <b-form-group label="Data Inicial" label-for="dataInicial">
                <validation-provider #default="{ errors }" name="Data Inicial">
                  <b-input-group>
                    <cleave
                      id="dataInicial"
                      type="text"
                      v-model="dataInicial"
                      placeholder="DD/MM/AAAA"
                      class="form-control cleave-height"
                      :options="options.cleaveDate"
                      :raw="false"
                    />
                    <b-input-group-append>
                      <b-form-datepicker
                        size="sm"
                        button-variant="primary"
                        class="datePickerWithInput"
                        right
                        button-only
                        v-model="dataInicialDatePicker"
                        :hide-header="true"
                        :initial-date="new Date()"
                        :state="errors[0] ? false : null"
                        label-help=""
                        label-calendar="Calendário"
                        label-close-button="Fechar"
                        label-current-month="Mês atual"
                        label-next-decade="Próxima década"
                        label-next-month="Próximo mês"
                        label-next-year="Próximo ano"
                        label-prev-decade="Década anterior"
                        label-prev-month="Mês anterior"
                        label-prev-year="Ano anterior"
                        label-select-month="Selecione o mês"
                        label-select-year="Selecione o ano"
                        label-selected="Selecionado"
                        label-no-date-selected="Nenhuma data selecionada"
                        label-reset-button="Resetar"
                        label-today-button="Hoje"
                        label-today="Hoje"
                        locale="pt-br"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: 'numeric' }"
                        aria-controls="dataInicial"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label="Data Final" label-for="dataFinal">
                <validation-provider #default="{ errors }" name="Data Inicial">
                  <b-input-group>
                    <cleave
                      id="dataFinal"
                      type="text"
                      v-model="dataFinal"
                      placeholder="DD/MM/AAAA"
                      class="form-control cleave-height"
                      :options="options.cleaveDate"
                      :raw="false"
                      :rules="{
                        dataPassada: {
                          value: dataInicial,
                          dataParametro: dataFinal,
                          stringParametro: 'data de inicial',
                        },
                      }"
                    />
                    <b-input-group-append>
                      <b-form-datepicker
                        size="sm"
                        button-variant="primary"
                        class="datePickerWithInput"
                        right
                        button-only
                        v-model="dataFinalDatePicker"
                        :hide-header="true"
                        :initial-date="new Date()"
                        :state="errors[0] ? false : null"
                        label-help=""
                        label-calendar="Calendário"
                        label-close-button="Fechar"
                        label-current-month="Mês atual"
                        label-next-decade="Próxima década"
                        label-next-month="Próximo mês"
                        label-next-year="Próximo ano"
                        label-prev-decade="Década anterior"
                        label-prev-month="Mês anterior"
                        label-prev-year="Ano anterior"
                        label-select-month="Selecione o mês"
                        label-select-year="Selecione o ano"
                        label-selected="Selecionado"
                        label-no-date-selected="Nenhuma data selecionada"
                        label-reset-button="Resetar"
                        label-today-button="Hoje"
                        label-today="Hoje"
                        locale="pt-br"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: 'numeric' }"
                        aria-controls="dataFinal"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Botões -->
          <b-row align-h="end">
            <b-col md="auto">
              <b-button
                :disabled="isBusy"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                style="margin-top: 21px; width: 160px"
                @click="carregarGrid()"
              >
                {{ !isBusy ? 'Prévia' : '' }}
                <b-spinner small v-if="isBusy" />
              </b-button>
            </b-col>
            <b-col md="auto">
              <template>
                <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
                  <template #button-content>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      style="margin-top: 21px; width: 160px"
                    >
                      Exportar
                    </b-button>
                  </template>
                  <b-dropdown-item href="#" @click="exportToPDF()">
                    <feather-icon icon="FileTextIcon" size="16" />
                    <span class="align-middle ml-50" title="Exportar para PDF">PDF</span>
                  </b-dropdown-item>
                  <b-dropdown-item href="#" @click="exportToExcel()">
                    <feather-icon icon="CheckCircleIcon" size="16" />
                    <span class="align-middle ml-50" title="Exportar para excel">Excel</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>

    <!-- TABELA -->
    <b-card v-if="this.itemsTabela.length > 0 || isBusy">
      <template v-if="isBusy">
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Carregando Tabela...</strong>
        </div>
      </template>

      <!-- TOTALIZADORES -->
      <div v-if="!isBusy" v-show="this.itemsTabela.length > 0">
        <b-row>
          <b-col md="2">
            <p>
              Total de Contratos Adiantados: <b-badge variant="primary"> {{ totalRows }}</b-badge>
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="2">
            <p>
              Valor Total: <b-badge variant="primary"> {{ this.valorTotal }}</b-badge>
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <!-- GRID -->
            <div ref="tabelaGrid">
              <b-table
                style="overflow-x: auto; -webkit-overflow-scrolling: touch"
                outlined
                id="tabelaParcelasAdiantadas"
                ref="tabelaParcelasAdiantadas"
                striped
                :per-page="perPage"
                :current-page="currentPage"
                :fields="this.currentFields"
                :items="this.itemsTabela"
                responsive="sm"
                class="position-relative custom-rounded-table"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
              >
              </b-table>

              <b-card-body v-if="this.itemsTabela.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
                <!-- page length -->
                <b-form-group
                  style="align-items: center"
                  label="Por Página"
                  label-cols="6"
                  label-align="left"
                  label-size="sm"
                  label-for="sortBySelect"
                  class="text-nowrap mb-md-0 mr-1"
                >
                  <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
                </b-form-group>

                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="this.itemsTabela.length"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-card-body>
            </div>
          </b-col>
        </b-row>
      </div>
      <div>
        <relatorioFinanceiroCapitalAplicadoXRentabilidadeRetrato ref="exportarPDF" :parametro="parametroPDF" />
      </div>
    </b-card>
  </div>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required, dataFutura, dataPassada, valor } from '@validations'
  import {
    formatarData,
    formatarDataGlobal,
    formatarValorReais,
    formatarParaNumber,
    formatarValorPorcentagem,
    formatarCompetenciaDescricao,
    formatarValor,
  } from '@/libs/utils'
  import Cleave from 'vue-cleave-component'
  import 'cleave.js/dist/addons/cleave-phone.br'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import relatorioFinanceiroCapitalAplicadoXRentabilidadeRetrato from './components/relatorioFinanceiroCapitalAplicadoXRentabilidadeRetrato.vue'
  import { VueSelect } from 'vue-select'
  import { generateAndDownloadPdf } from '@/utils/geradorPDF/geradorPDF.js'
  import VueHtml2pdf from 'vue-html2pdf'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import * as XLSX from 'xlsx'
  import * as dateFns from 'date-fns'

  export default {
    components: {
      valor,
      VueHtml2pdf,
      ToastificationContent,
      formatarData,
      formatarValorPorcentagem,
      formatarValorReais,
      generateAndDownloadPdf,
      formatarCompetenciaDescricao,
      relatorioFinanceiroCapitalAplicadoXRentabilidadeRetrato,
      useJwt,
      dataPassada,
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      Cleave,
      VueSelect,
      required,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        valorTotal: 0,
        itemsTabela: [],
        tipoFiltroSelecionado: null,
        valor: 0,
        isBusy: false,
        nomeRelatorio: 'Relatório de Parcelas Antecipadas',
        competencia: '',
        pesquisarPor: '',
        parametroPDF: {
          dados: [],
          colunas: [],
          totalValores: [],
        },
        linhasTabela: [],
        datePicker: {
          dataInicial: '',
          dataFinal: '',
        },
        dataInicialDatePicker: '',
        dataFinalDatePicker: '',
        dataInicial: '',
        dataFinal: '',
        options: {
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
          cleaveCPF: {
            numericOnly: true,
            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 2],
          },
          cleaveCodigo: {
            numericOnly: true,
            delimiters: ['/'],
            blocks: [6, 2],
          },
        },
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filtroCapital: {
          ano: '',
          mes: '',
        },
        anoOpcoes: [
          { value: 2021, text: '2021' },
          { value: 2022, text: '2022' },
          { value: 2023, text: '2023' },
          { value: 2024, text: '2024' },
          { value: 2025, text: '2025' },
          { value: 2026, text: '2026' },
          { value: 2027, text: '2027' },
          { value: 2028, text: '2028' },
          { value: 2029, text: '2029' },
          { value: 2030, text: '2030' },
        ],
        mesOpcoes: [
          { value: 1, text: 'Janeiro' },
          { value: 2, text: 'Fevereiro' },
          { value: 3, text: 'Março' },
          { value: 4, text: 'Abril' },
          { value: 5, text: 'Maio' },
          { value: 6, text: 'Junho' },
          { value: 7, text: 'Julho' },
          { value: 8, text: 'Agosto' },
          { value: 9, text: 'Setembro' },
          { value: 10, text: 'Outubro' },
          { value: 11, text: 'Novembro' },
          { value: 12, text: 'Dezembro' },
        ],
        number: {},
        colunasTabelas: [],
        currentFields: [
          {
            key: 'codigo',
            label: 'Nº do Contrato',
            sortable: true,
          },
          {
            key: 'justificativa',
            label: 'Origem',
            sortable: true,
          },
          {
            key: 'parcelas',
            label: 'Nº de Parcelas',
            sortable: true,
          },
          {
            key: 'valor',
            label: 'Valor',
            sortable: true,
          },
          {
            key: 'amortizacao',
            label: 'Amortizacao',
            sortable: true,
          },
          {
            key: 'juros',
            label: 'Juros',
            sortable: true,
          },
          {
            key: 'rentabilidade',
            label: 'Rentabilidade',
            sortable: true,
          },
          {
            key: 'fundoGarantidor',
            label: 'Fundo Garantidor',
            sortable: true,
          },
          {
            key: 'administracao',
            label: 'Administração',
            sortable: true,
          },
          {
            key: 'oscilacaoRiscos',
            label: 'Oscilação de Riscos',
            sortable: true,
          },
        ],
        currentFieldsPDF: [
          {
            key: 'codigo',
            label: 'Nº do Contrato',
            sortable: true,
          },
          {
            key: 'justificativa',
            label: 'Origem',
            sortable: true,
          },
          {
            key: 'parcelas',
            label: 'Nº de Parcelas',
            sortable: true,
          },
          {
            key: 'valor',
            label: 'Valor',
            sortable: true,
          },
          {
            key: 'amortizacao',
            label: 'Amortizacao',
            sortable: true,
          },
          {
            key: 'juros',
            label: 'Juros',
            sortable: true,
          },
          {
            key: 'rentabilidade',
            label: 'Rentabilidade',
            sortable: true,
          },
          {
            key: 'fundoGarantidor',
            label: 'Fundo Garantidor',
            sortable: true,
          },
          {
            key: 'administracao',
            label: 'Administração',
            sortable: true,
          },
          {
            key: 'oscilacaoRiscos',
            label: 'Oscilação de Riscos',
            sortable: true,
          },
        ],
      }
    },
    mounted() {
      this.dataInicial = '0101' + new Date().getFullYear().toString()
      let ultimoDiaDoMes = dateFns.lastDayOfMonth(new Date())
      this.dataFinal = dateFns.format(ultimoDiaDoMes, 'ddMMyyyy')
    },
    methods: {
      carregarGrid() {
        this.isBusy = true
        const mes = this.filtroCapital.mes < 10 ? `0${this.filtroCapital.mes}` : this.filtroCapital.mes
        this.competencia = `${this.filtroCapital.ano}${mes}`

        let filtro = {
          institutoId: this.institutoId,
          dataInicial: formatarDataGlobal(this.dataInicial),
          dataFinal: formatarDataGlobal(this.dataFinal),
        }

        useJwt
          .post('parcelas-antecipadas/GetRelatorioFinanceiroParcelasAntecipadas', filtro)
          .then((response) => {
            if (!response.data.length > 0) {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Alerta!',
                    text: 'Não há dados para o filtro inserido!',
                    icon: 'BellIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-right',
                  timeout: 8000,
                },
              )
              this.itemsTabela = []

              return
            }

            this.itemsTabela = response.data.map((item) => ({
              ...item,
              id: item.id,
              codigo: item.codigo,
              justificativa: item.justificativa,
              parcelas: item.parcelas,
              valor: formatarValor(item.valor),
              amortizacao: formatarValor(item.amortizacao),
              juros: formatarValor(item.juros),
              rentabilidade: formatarValor(item.rentabilidade),
              fundoGarantidor: formatarValor(item.fundoGarantidor),
              administracao: formatarValor(item.administracao),
              oscilacaoRiscos: formatarValor(item.oscilacaoRiscos),
            }))

            let valorTotalAux = 0
            response.data.map((item) => {
              valorTotalAux = formatarParaNumber(valorTotalAux) + formatarParaNumber(item.valor)
            })

            this.valorTotal = formatarValor(valorTotalAux)
            this.totalRows = this.itemsTabela.length
            this.items = response.data
            this.linhasTabela = this.itemsTabela
            ;(this.colunasTabelas = this.currentFieldsPDF.map((item) => ({
              label: item.label,
              key: item.key,
            }))),
              // Monta variável global de relatório PDF
              (this.parametroPDF = {
                dados: this.linhasTabela,
                colunas: this.colunasTabelas,
                totalValores: this.valorTotal,
              })
          })
          .catch((error) => {
            console.error(error)
            this.isBusy = false
          })
          .finally(() => {
            this.isBusy = false
          })
      },
      exportToPDF() {
        if (!this.itemsTabela.length > 0) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Alerta!',
                text: 'Não há dados para exportar!',
                icon: 'BellIcon',
                variant: 'danger',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
          return
        }
        if (this.$refs.tabelaGrid) {
          let htmlContent = this.$refs.exportarPDF.$children[0].$refs.html2Pdfportrait.$el.innerHTML
          let relatorioPaisagem = true
          let periodoFiltro = `Período: ${this.dataInicial} a ${this.dataFinal}`
          let nomeRelatorio = this.nomeRelatorio

          generateAndDownloadPdf(this, htmlContent, periodoFiltro, nomeRelatorio, relatorioPaisagem)
        } else {
          console.error('Elemento da tabela não encontrado')
        }
      },
      exportToExcel() {
        if (!this.itemsTabela.length) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Alerta!',
                text: 'Não há dados para exportar!',
                icon: 'BellIcon',
                variant: 'danger',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
          return
        }

        const dataToExcel = this.itemsTabela.map((item) => ({
          'Nº do Contrato': item.codigo,
          Origem: item.justificativa,
          'Nº de Parcelas': item.parcelas,
          Valor: String(formatarParaNumber(item.valor)).replace('.', ','),
          Amortizacao: String(formatarParaNumber(item.amortizacao)).replace('.', ','),
          Juros: String(formatarParaNumber(item.juros)).replace('.', ','),
          Rentabilidade: String(formatarParaNumber(item.rentabilidade)).replace('.', ','),
          'Fundo Garantidor': String(formatarParaNumber(item.fundoGarantidor)).replace('.', ','),
          Administração: String(formatarParaNumber(item.administracao)).replace('.', ','),
          'Oscilação de Riscos': String(formatarParaNumber(item.oscilacaoRiscos)).replace('.', ','),
        }))

        // Usando os dados e colunas para criar a planilha
        const ws = XLSX.utils.json_to_sheet(dataToExcel)

        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Relatorio')

        // Salvando o arquivo
        XLSX.writeFile(wb, 'Relatorio.xlsx')
      },
    },
    watch: {
      dataInicialDatePicker(newVal) {
        this.dataInicial = formatarData(this.dataInicialDatePicker)
      },
      dataFinalDatePicker(newVal) {
        this.dataFinal = formatarData(this.dataFinalDatePicker)
      },
    },
  }
</script>
